import { useEffect, useState, useRef } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";

import Toast from "../../../Utils/Toast";
import { appConfig } from "../../../../config/Config";
import { isBrowser } from "react-device-detect";

import "./LiveShare.css";

interface propsType {
  broad_seq: string;
  broad_title: string;
  broad_img: string;
  livart_broad_seq: string;
  iconSize: string;
  postMsg: any;
  hidden_menu: boolean;
  isNotFloat: any;
  isFloat: any;
}
declare const window: any;
const LiveShare = (props: propsType) => {
  const [openShare, setOpenShare] = useState(false);

  const toastRef: any = useRef();

  if (!window.Kakao.isInitialized()) {
    window.Kakao.init(appConfig.kakao_app_key);
  }

  useEffect(() => {
    return () => {
      // 메모리 누수를 방지하기 위해서 컴포넌트 언마운트시 State를 초기화 한다.
      setOpenShare(false);
    };
  }, []);

  const copyURL = () => {
    console.log("URL 복사 클릭");
  };

  const handleShareModalClose = () => {
    setOpenShare(false);
    props.isFloat();
  };
  const handleShareModalOpen = () => {
    setOpenShare(true);
  };

  const fncShareKakao = () => {
    window.Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: props.broad_title,
        imageUrl: props.broad_img,
        link: {
          mobileWebUrl: `https://www.hyundailivart.co.kr/liveViewer/${props.livart_broad_seq}/${props.broad_seq}`,
          webUrl: `https://www.hyundailivart.co.kr/liveViewer/${props.livart_broad_seq}/${props.broad_seq}`,
        },
      },
      buttons: [
        {
          title: "웹으로 보기",
          link: {
            mobileWebUrl: `https://www.hyundailivart.co.kr/liveViewer/${props.livart_broad_seq}/${props.broad_seq}`,
            webUrl: `https://www.hyundailivart.co.kr/liveViewer/${props.livart_broad_seq}/${props.broad_seq}`,
          },
        },
      ],
    });
  };

  return (
    <>
      <Box
        sx={props.hidden_menu ? { display: "none" } : { display: "block", cursor: `${isBrowser ? "pointer" : ""}` }}
        onClick={handleShareModalOpen}
      >
        <img className="footer-right-icon" src="/images/btn-share.png" alt="" />
      </Box>
      <Modal
        open={openShare}
        onClose={handleShareModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="modal-share">
          <Box className="modal-content">
            <Box className="live-qna-drawer-top">
              <Box component="img" src="/images/icon-share.png" className="share-title-icon" />
              <div className="share-title-text">공유하기</div>
              <Box className="live-qna-drawer-close">
                <Box component="img" src="/images/notice-close.svg" onClick={handleShareModalClose} />
              </Box>
            </Box>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Stack className="stack-row" direction="row" justifyContent="space-evenly" alignItems="center" spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                  onClick={fncShareKakao}
                  sx={{ cursor: `${isBrowser ? "pointer" : ""}` }}
                >
                  <Avatar className="item-small" alt="Rem Sharp" src="/images/icon-kakao.png" />
                  <Typography id="modal-modal-title" variant="caption">
                    카카오톡
                  </Typography>
                </Stack>
                <CopyToClipboard
                  text={`https://www.hyundailivart.co.kr/liveViewer/${props.livart_broad_seq}/${props.broad_seq}`}
                  onCopy={() => {
                    toastRef.current?.toast("클립보드에 복사가 완료되었습니다.", "success", 3000);
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={1}
                    onClick={copyURL}
                    sx={{ cursor: `${isBrowser ? "pointer" : ""}` }}
                  >
                    <Avatar className="item-small" alt="Rem Sharp" src="/images/icon-linkcopy.png" />
                    <Typography id="modal-modal-title" variant="caption">
                      URL 복사
                    </Typography>
                  </Stack>
                </CopyToClipboard>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <Toast ref={toastRef} />
    </>
  );
};

export default LiveShare;
